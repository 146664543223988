import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import CivicDonationWrapper from './CivicDonationWrapper'

function Donationmodal(props) {
  console.log(props.amount)
   return (
        <>
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            amount={props.amount}
            rank={props.rank}
            type={props.type}
            
          ><Modal.Header closeButton>
          <Modal.Title>
              Donate to USS IDAHO Commissioning Committee
          </Modal.Title>
          </Modal.Header>
            <Modal.Body> 
            {/* Donate ${props.amount / 100} */}
            <CivicDonationWrapper rank={props.rank} amount={props.amount} type={props.type}
            />
            </Modal.Body>  
            </Modal>      
        </>
    )
}

export default Donationmodal
