import React, { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLevelUpAlt} from "@fortawesome/pro-solid-svg-icons"
import * as styles from "../../DonateButton/donateButton.module.css"
import RECAPTCHA from "react-google-recaptcha"

const IndividualDonorForm = (props) => {

    const { register, watch, errors, reset, formState } = useForm({
        mode: "onChange"
    });

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');

    const watchObject = watch();

    const [disableSubmit, setDisableSubmit] = useState(true)

    const handleChange = async (event) => {
        setError(event.error ? event.error.message : "");
        console.log(watchObject)
    }

    const handleSubmit = async ev => {
        let data = null
        if (typeof document !== undefined) {
            data = document.forms["join-form"]
          }
        ev.preventDefault();
        setProcessing(true);
        
        //alert(JSON.stringify(watchObject));
        setSucceeded(true);

        fetch("https://rrm0b7tl6b.execute-api.us-west-2.amazonaws.com/production/uss-idaho-donation-inquiry", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(watchObject)
                // Message: Message,
                // Volunteer_Name: Volunteer_Name,
                // Mailing_address: Mailing_address,
                // City: City,
                // State: State,
                // Email: Email,
                // Phone: Phone,
            
        }) 
        .then(response => {
            if (response.status === 200) {
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                reset()
            } else {
                setError(`Your message failed to send. Please try again. ${error}`);
                setProcessing(false);
            }
        }).then (
            fetch(
                `https://script.google.com/macros/s/AKfycbwiSY7pROEdu0kknzOU6DaT-N9bqIEZCMCW8HP-xgrIKhtrIeo/exec`,
                {
                    method: "POST",
                    body: new FormData(data),
                }
            )
            .then((response) => {
                if (response.status === 200) {
                  console.log('Data submitted to Google Sheet') 
                  console.log(JSON.stringify(watchObject))
                } else {
                    console.log('Error submiting to Google Sheet')
                }
            })
            .catch((error) => console.log('Google error!', error.message))
        )
    }

    const SubmitSuccessMessage = () => (
        <div className="submit-success-message">
            <h3>Thank you for your submission.</h3>
            <p>A representative will be in touch shortly.</p>
        </div>
    )

    return (
    <>
        <div>
            <p><em>I am interested in donating $<span>{(props.amount / 100).toLocaleString()}</span> to the USS IDAHO Commissioning Foundation, but I have some questions. Please contact me at the earliest opportunity.</em></p> 
    <form id="join-form" onSubmit={handleSubmit} onChange={handleChange} noValidate>
        <input 
            type="hidden"
            
            {...register("Organization_Type")}
            name="Organization_Type"
            value="Corporate"
        />
        <input
            type="hidden"
                        {...register("Donation_Amount")}
            name="Donation_Amount"
            value={(props.amount / 100).toLocaleString()} />
        <Form.Group>
            <Row>
                <Col>
                    <Form.Label>
                        <span style={{fontWeight: 700}}>Your Name:  </span>
                    </Form.Label>
                    
                    <Form.Control 
                        type="text"
                        id="name"
                        name="Name"
                        placeholder="Your Full Name"
                        {...register("Name", { required: true })}
                    />
                    {" " }{errors.name && <span className="formError">Please enter your name <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span>}
                </Col>
            </Row>
        </Form.Group>
        <Form.Group>
            <Row>
                <Col>
                    <Form.Label>
                        <span style={{fontWeight: 700}}>Title:  </span>
                    </Form.Label>
                    
                    <Form.Control 
                        type="text"
                        id="title"
                        name="Title"
                        placeholder="Title"
                        {...register("Title", { required: true })}
                    />
                    {" " }{errors.name && <span className="formError">Please enter your title <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span>}
                </Col>
                <Col>
                    <Form.Label>
                        <span style={{fontWeight: 700}}>Corporation:  </span>
                    </Form.Label>
                    
                    <Form.Control 
                        type="text"
                        id="corporation"
                        name="Organization_Name"
                        placeholder="Corporation"
                        {...register("Organization_Name", { required: true })}
                    />
                    {" " }{errors.name && <span className="formError">Please enter your corporation name <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span>}
                </Col>
            </Row>
        </Form.Group>
        <Form.Group>
            <Row className="my-2">
                <Col sm={6}>
                    <Form.Label>
                        <span style={{fontWeight: 700}}>Email Address:  </span>
                    </Form.Label>
                    <Form.Control 
                        type="email"
                        id="email"
                        name="Email"
                        placeholder="Your email address"
                        {...register("Email", { required: "Enter your e-mail",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Enter a valid e-mail address",
            }, })}
          />
          {" "}{errors.Email && <span className="formError">{errors.Email.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> } 
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="mb-2">
                  <Form.Label style={{fontWeight: 700}}>Phone number</Form.Label>
                  <Form.Control
                    type="text"
                    id="phone"
                    name="Phone"
                    placeholder="Enter your phone number"
                    {...register("phone", { pattern: {
                      value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                      message: "Please enter a valid phone number"
                    },
                })}                
                  />
                  {" "}{errors.Phone && <span className="formError">{errors.Phone.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
                </Col>
                <Col>
                    <Form.Label as="legend"  style={{fontWeight: 700}}>Type</Form.Label>
                    <Form.Check
                      inline
                      type="radio"
                      label="Mobile (preferred)"
                      value="mobile"
                      name="phoneType"
                      id="mobile"
                      defaultChecked
                      {...register("phoneType")}
                      
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Work"
                      value="work"
                      name="phoneType"
                      id="work"
                      {...register("phoneType")}
                      
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Home"
                      value="home"
                      name="phoneType"
                      id="home"
                      {...register("phoneType")}
                      
                    />
                </Col>
            </Row>
            <Row>
                <Col>
            <div className="my-2">
              <RECAPTCHA 
                sitekey="6LeuCCIaAAAAAEHsnYs6bu5U1tOkLvw6NXcFh5pK"
                size="normal"

                onChange={useCallback(() => setDisableSubmit(false))}
              />
            </div> 
            </Col>
            </Row>
            <Row className="my-4">
                <Col>
                {formState.isValid || succeeded ? (
              <Button type="submit" variant="primary" form="join-form" disabled={disableSubmit} className="submitButton teamButton">
                <span id="button-text">
                                { succeeded ? (
                                  'Message sent!'
                                ) :
                                processing ? (
                                    'Submitting...'
                                ) : 'Submit' }
                            </span>
            </Button>
            ) : null } 
                </Col>
            </Row>
        </Form.Group>
    </form>
    </div>

    </>
    )
}

export default IndividualDonorForm