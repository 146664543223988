import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 150px;
  height: 150px;
  .cls-1 {
        fill: #002664;
      }

      .cls-10, .cls-11, .cls-2, .cls-3, .cls-4, .cls-5, .cls-7, .cls-9 {
        fill: #fff;
      }

      .cls-10, .cls-11, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #002664;
      }

      .cls-11, .cls-3, .cls-4, .cls-6 {
        stroke-linecap: square;
        stroke-linejoin: round;
      }

      .cls-3, .cls-5 {
        stroke-width: 0.36907px;
      }

      .cls-4, .cls-6 {
        stroke-width: 0.47561px;
      }

      .cls-5 {
        stroke-linecap: round;
      }

      .cls-6, .cls-8 {
        fill: none;
      }

      .cls-7, .cls-8 {
        stroke-width: 0.30439px;
      }

      .cls-9 {
        stroke-width: 0.29488px;
      }

      .cls-10 {
        stroke-width: 0.42805px;
      }

      .cls-11 {
        stroke-width: 0.19024px;
      }
`;

const NavySecretary = (props) => {
    return (

<Logo style={props.style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 209.7561">
  <defs>
    <style>
      
    </style>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <rect id="rect5" class="cls-1" width="300" height="209.7561"/>
      <polygon id="polygon1529" class="cls-2" points="45.732 135.561 58.537 174.927 25.015 150.585 66.439 150.585 32.927 174.927 45.732 135.561"/>
      <polygon id="polygon1529-2" data-name="polygon1529" class="cls-2" points="45.732 30.663 58.537 70.049 25.015 45.717 66.439 45.717 32.927 70.049 45.732 30.663"/>
      <polygon id="polygon1529-3" data-name="polygon1529" class="cls-2" points="254.293 30.663 267.073 70.049 233.561 45.717 274.976 45.717 241.463 70.049 254.293 30.663"/>
      <polygon id="polygon1529-4" data-name="polygon1529" class="cls-2" points="254.293 135.561 267.073 174.927 233.561 150.585 274.976 150.585 241.463 174.927 254.293 135.561"/>
      <g id="g27">
        <path id="path4040" class="cls-3" d="M158.439,38.78537a2.19763,2.19763,0,1,1-2.19512-2.09269A2.14184,2.14184,0,0,1,158.439,38.78537Z"/>
        <path id="path34" class="cls-4" d="M150.29269,27.27805A13.09269,13.09269,0,1,0,163.36585,40.361,13.07915,13.07915,0,0,0,150.29269,27.27805Zm0,20.13658a7.05125,7.05125,0,1,1,7.07317-7.04878A7.0221,7.0221,0,0,1,150.29269,47.41463Z"/>
        <g id="g40">
          <path id="path42" class="cls-5" d="M148.34147,101.122c1.65853-1.561-.29269-4.92683.19512-7.21951-4.09756.73171-1.46342,5.17073-1.60975,7.90244a2.94927,2.94927,0,0,0,1.41463-.68293h0"/>
          <path id="path44" class="cls-5" d="M146.39025,103.122c1.60975-1.60976-.48781-4.92683-.04879-7.21952-4.09755.87805-1.21951,5.21952-1.31708,7.95123a2.77837,2.77837,0,0,0,1.36585-.73171h0"/>
          <path id="path46" class="cls-5" d="M144.73171,105.07317c1.41463-1.80488-.878-4.82927-.53659-7.122-4.04877,1.0244-.9756,5.31707-.878,8.04878a2.86589,2.86589,0,0,0,1.41463-.92682h0"/>
          <path id="path48" class="cls-5" d="M175.17073,176.53658c.48781-2.2439-3.21951-3.95122-4.73171-5.75609-1.85365,3.7561,2.68293,4.87805,3.80487,7.31707a3.1455,3.1455,0,0,0,.92683-1.561h0"/>
          <path id="path50" class="cls-5" d="M175.60976,173.07316c.14633-2.29268-3.80488-3.41463-5.51219-4.97561-1.46342,3.90244,3.41463,4.48781,4.78048,6.82927a3.59277,3.59277,0,0,0,.73171-1.85366h0"/>
          <path id="path52" class="cls-5" d="M175.561,169.70731c-.09756-2.2439-4.24391-2.92683-6.19513-4.19512-.78049,4.09756,4,3.90244,5.70733,6a2.9801,2.9801,0,0,0,.4878-1.80488h0"/>
          <path id="path54" class="cls-5" d="M174.97562,166.2439c-.48781-2.24391-4.63415-2.19513-6.73171-3.17074-.19511,4.14635,4.48782,3.2683,6.53659,5.02439a3.19166,3.19166,0,0,0,.19512-1.85365h0"/>
          <path id="path56" class="cls-5" d="M173.90244,162.92683c-.82928-2.14634-4.82927-1.5122-7.07317-2.19512.29268,4.14634,4.97561,2.68292,7.2195,4.19512a3.34959,3.34959,0,0,0-.14633-2h0"/>
          <path id="path58" class="cls-5" d="M150.39024,99.21951c1.70732-1.5122-.09757-4.92683.39024-7.21951-4.09756.7317-1.561,5.17073-1.95122,7.90243a3.57907,3.57907,0,0,0,1.561-.68292h0"/>
          <path id="path60" class="cls-5" d="M152.48781,97.36586c1.7561-1.46342.04878-4.97561.58537-7.21952-4.14634.63415-1.70732,5.17074-2.14634,7.85366a3.083,3.083,0,0,0,1.561-.63414h0"/>
          <path id="path62" class="cls-5" d="M154.63415,95.561c1.80488-1.46341.14633-5.02439.68292-7.26829-4.09756.63415-1.70732,5.12195-2.19512,7.80488a2.73648,2.73648,0,0,0,1.5122-.53659h0"/>
          <path id="path64" class="cls-5" d="M156.92684,93.70732c1.7561-1.46341.09756-4.97561.68292-7.26829-4.14634.63415-1.7561,5.17074-2.29269,7.80488a2.85951,2.85951,0,0,0,1.60975-.53659h0"/>
          <path id="path66" class="cls-5" d="M172.39024,159.85366c-1.12194-2-4.92683-.878-7.21951-1.31707.7805,4.04877,5.17074,2.09755,7.60976,3.26829a3.32818,3.32818,0,0,0-.39023-1.95122h0"/>
          <g id="g68">
            <path id="path72" class="cls-2" d="M193.90244,124.63415c-13.21952,8.87805-18.92682,17.561-21.7561,27.31707C172,152.04879,180,154,180,154c-4.19512,6.04877-7.07317,7.31707-12.78049,8.4878-5.561,1.12194-10.58538-.439-10.68293-10.82928L155.561,54.58536H145.02438l-.97561,97.07317c-.04878,10.39024-5.07316,11.95122-10.68292,10.82928-4.53658-.92683-7.70731-2.97561-11.07317-6.97561l8.68293.24391c-1.90244-13.95123-12.48781-24.97562-24.63415-31.17073-6.14634,11.21951-6.04877,21.31708-2.2439,30.439l9.65854.29269c6.97561,14.04877,16.09756,18.7317,25.90243,21.561,6.68293,1.95122,9.5122,4.29267,10.63414,5.60975a30.89427,30.89427,0,0,1,10.63414-5.60975c9.70732-3.21952,17.85366-7.36586,25.70732-21.0244.04879.09757,8.34147,2.63415,8.439,2.73171,4.14634-10.09756,5.46342-26.63415-1.17073-33.95122Z"/>
            <path id="path76" class="cls-6" d="M193.90244,124.63415c-13.21952,8.87805-18.92682,17.561-21.7561,27.31707C172,152.04879,180,154,180,154c-4.19512,6.04877-7.07317,7.31707-12.78049,8.4878-5.561,1.12194-10.58538-.439-10.68293-10.82928L155.561,54.58536H145.02438l-.97561,97.07317c-.04878,10.39024-5.07316,11.95122-10.68292,10.82928-4.53658-.92683-7.70731-2.97561-11.07317-6.97561l8.68293.24391c-1.90244-13.95123-12.48781-24.97562-24.63415-31.17073-6.14634,11.21951-6.04877,21.31708-2.2439,30.439l9.65854.29269c6.97561,14.04877,16.09756,18.7317,25.90243,21.561,6.68293,1.95122,9.5122,4.29267,10.63414,5.60975a30.89427,30.89427,0,0,1,10.63414-5.60975c9.70732-3.21952,17.85366-7.36586,25.70732-21.0244.04879.09757,8.34147,2.63415,8.439,2.73171,4.14634-10.09756,5.46342-26.63415-1.17073-33.95122Z"/>
          </g>
          <g id="g78">
            <polygon id="polygon80" class="cls-7" points="104.634 54.341 104.634 64.293 105.854 66.683 195.024 56.732 193.756 54.341 149.22 53.415 104.634 54.341"/>
            <polygon id="polygon82" class="cls-7" points="150.439 69.756 195.024 66.683 195.024 56.732 150.439 57.659 105.854 56.732 105.854 66.683 150.439 69.756"/>
            <line id="line84" class="cls-8" x1="104.63415" y1="54.34146" x2="105.85366" y2="56.73171"/>
            <g id="g86">
              <polygon id="polygon88" class="cls-9" points="112.732 56.634 110.829 56.585 110.829 67.268 112.732 67.415 112.732 56.634"/>
            </g>
            <polygon id="polygon90" class="cls-9" points="110.829 56.585 112.732 56.634 111.268 53.805 109.415 53.805 110.829 56.585"/>
            <polygon id="polygon92" class="cls-9" points="188.146 56.634 190 56.585 190 67.268 188.146 67.415 188.146 56.634"/>
            <polygon id="polygon94" class="cls-9" points="190 56.585 188.146 56.634 186.683 53.805 188.585 53.805 190 56.585"/>
          </g>
          <path id="path96" class="cls-5" d="M143.17073,107.26829c1.2683-1.90244-1.21951-4.78049-1.07317-7.07317-3.95123,1.31707-.58538,5.26829-.14634,7.95122a2.46869,2.46869,0,0,0,1.21951-.87805h0"/>
          <path id="path98" class="cls-5" d="M141.85365,109.46341c1.12195-2-1.65853-4.63414-1.65853-6.92683-3.85366,1.561-.14634,5.2683.58537,7.90244a2.65509,2.65509,0,0,0,1.07318-.97561h0"/>
          <path id="path100" class="cls-5" d="M140.82927,111.70732c.87805-2.09757-2.2439-4.39025-2.39024-6.73171-3.7561,1.80487.39024,5.26829,1.46342,7.80488.53659-.48781.78049-.63415.92682-1.07317h0"/>
          <path id="path102" class="cls-5" d="M140.14634,114.04878c.53658-2.2439-2.7805-4.04878-3.21952-6.34146C133.4634,110,138,112.92683,139.31706,115.31708a2.795,2.795,0,0,0,.82928-1.2683h0"/>
          <path id="path104" class="cls-5" d="M139.7561,116.39025c.19512-2.29268-3.2683-3.561-4-5.7561-3.17073,2.68292,1.70732,4.97561,3.36585,7.17073a3.42023,3.42023,0,0,0,.63414-1.41463h0"/>
          <path id="path106" class="cls-5" d="M139.65853,118.92683c0-2.2439-3.70732-3.21951-4.73171-5.26829-2.73171,3.12194,2.39024,4.7317,4.24391,6.73171a2.869,2.869,0,0,0,.4878-1.46342h0"/>
          <path id="path108" class="cls-5" d="M139.85366,121.31707c-.34146-2.2439-4.09755-2.63414-5.31707-4.63414-2.439,3.41463,2.87805,4.439,5.02439,6.14634a2.73378,2.73378,0,0,0,.29268-1.5122h0"/>
          <path id="path110" class="cls-5" d="M140.39024,123.80488c-.58537-2.19512-4.34146-2.19512-5.85366-3.95122-1.90244,3.70732,3.46342,4,5.75609,5.46341a3.35065,3.35065,0,0,0,.09757-1.5122h0"/>
          <path id="path112" class="cls-5" d="M141.17073,126.09756c-.878-2.09756-4.58536-1.60976-6.29268-3.17073-1.46342,3.85365,3.90244,3.561,6.39025,4.73171a2.642,2.642,0,0,0-.09757-1.561h0"/>
          <path id="path114" class="cls-5" d="M142.29269,128.439c-1.02439-2.04879-4.73171-1.17073-6.68293-2.48781-.87805,4.09756,4.2439,3.07317,6.87805,3.85366,0-.68293.04878-.97561-.19512-1.36585h0"/>
          <path id="path116" class="cls-5" d="M143.70732,130.63414c-1.2683-1.95122-4.97562-.68292-6.9756-1.80488-.53659,4.14634,4.63414,2.63414,7.31707,3.21951a2.741,2.741,0,0,0-.34147-1.41463h0"/>
          <path id="path118" class="cls-5" d="M145.26829,132.78049c-1.36585-1.85365-4.97562-.29268-7.122-1.17072,0,4.14634,4.92683,2.09755,7.60976,2.58535a2.39988,2.39988,0,0,0-.4878-1.41463h0"/>
          <path id="path120" class="cls-5" d="M146.9756,134.68293c-1.561-1.65853-4.97561.19512-7.17073-.58537.2439,4.19512,5.17073,1.80488,7.85366,2.14634a3.3299,3.3299,0,0,0-.68293-1.561h0"/>
          <path id="path122" class="cls-5" d="M148.92682,136.68293c-1.60975-1.60975-4.92683.39024-7.2195-.09756.78049,4.09755,5.17073,1.36584,7.90244,1.51219a2.94936,2.94936,0,0,0-.68292-1.41463h0"/>
          <path id="path124" class="cls-5" d="M150.97561,138.58537c-1.65853-1.561-4.97562.58537-7.26829.19512.97561,4.04877,5.26829,1.17073,8,1.21951a2.9661,2.9661,0,0,0-.73171-1.41463h0"/>
          <path id="path126" class="cls-5" d="M153.07316,140.439c-1.70731-1.5122-4.92683.73171-7.2195.48781,1.07318,4,5.36585.92682,8.04878.97561a3.21414,3.21414,0,0,0-.82928-1.46342h0"/>
          <path id="path128" class="cls-5" d="M170.53659,157.02439c-1.36585-1.85366-4.97562-.29269-7.26829-.63414.97561,4.04877,5.31707,1.65853,7.90244,2.4878a3.35065,3.35065,0,0,0-.63414-1.85365h0"/>
          <path id="path130" class="cls-5" d="M168.58537,154.53658c-1.46342-1.70732-5.02439.09757-7.31707-.19512,1.12194,4.04877,5.36585,1.31708,8.04878,1.95122a3.42645,3.42645,0,0,0-.73171-1.7561h0"/>
          <path id="path132" class="cls-5" d="M166.4878,152.24391c-1.561-1.65854-5.02439.39023-7.36586.14633,1.2683,4,5.51219,1.07318,8.19512,1.5122a3.14139,3.14139,0,0,0-.82928-1.65853h0"/>
          <path id="path134" class="cls-5" d="M164.34146,150.09757c-1.65853-1.561-5.07317.58537-7.36586.439,1.31708,3.95122,5.36586.878,8.09757,1.07318a3.36828,3.36828,0,0,0-.73171-1.5122h0"/>
          <path id="path136" class="cls-5" d="M162,148c-1.70731-1.46342-4.92683.78049-7.26829.63414,1.41463,3.95122,5.41464.78049,8.14635.92682A3.64906,3.64906,0,0,0,162,148h0"/>
          <path id="path138" class="cls-5" d="M159.7561,146.09756c-1.70732-1.46341-4.92683.78049-7.26829.68292,1.41463,3.90244,5.41463.73171,8.14635.7805a3.2518,3.2518,0,0,0-.87805-1.46342h0"/>
          <path id="path140" class="cls-5" d="M157.51219,144.19512c-1.7561-1.46341-4.92683.82928-7.26829.68293,1.36585,3.95122,5.41464.73171,8.14635.78049a3.41512,3.41512,0,0,0-.878-1.46342h0"/>
          <path id="path142" class="cls-5" d="M155.26829,142.34146c-1.7561-1.5122-4.92684.78049-7.26829.58537,1.21951,4,5.41463.82927,8.09756.82927a3.025,3.025,0,0,0-.82927-1.41463h0"/>
          <path id="path144" class="cls-5" d="M174.19512,178.68292c.439-2.2439-2.29268-1.95122-4.14634-5.31707-1.26829,1.7561-1.02439,5.561.92683,6.34146C172.24391,180.2439,173.90244,180.09755,174.19512,178.68292Z"/>
          <path id="path146" class="cls-5" d="M159.17073,91.85366c1.80488-1.41463.14633-5.02439.63414-7.26829-4.09756.73171-1.65854,5.17074-2.19513,7.85366a3.08079,3.08079,0,0,0,1.561-.58536h0"/>
          <path id="path148" class="cls-5" d="M161.5122,89.95122c1.7561-1.5122.04878-4.97561.439-7.26829-4.04878.87805-1.561,5.21951-2.09756,7.90243a3.27917,3.27917,0,0,0,1.65854-.63414h0"/>
          <path id="path150" class="cls-5" d="M163.70732,88.04878c1.7561-1.5122,0-5.02439.34147-7.31707-4.04878.97561-1.41463,5.21951-1.90244,7.90243a2.90794,2.90794,0,0,0,1.561-.58536h0"/>
          <path id="path152" class="cls-5" d="M166,86c1.70732-1.5122-.2439-5.02439,0-7.36586-4,1.17073-1.17072,5.41464-1.60975,8.09757A3.29754,3.29754,0,0,0,166,86h0"/>
          <path id="path154" class="cls-5" d="M168.19512,83.90244c1.65853-1.60976-.39024-5.07317-.39024-7.36586-3.85366,1.561-.82928,5.36586-1.12195,8.04878a3.36,3.36,0,0,0,1.5122-.68292h0"/>
          <path id="path156" class="cls-5" d="M170.39024,81.5122c1.5122-1.70732-.82928-4.97561-.97561-7.31708-3.75611,1.85366-.439,5.5122-.63414,8.2439a3.34059,3.34059,0,0,0,1.60975-.92682h0"/>
          <path id="path158" class="cls-5" d="M172.34146,79.07317c1.41463-1.80487-1.12195-4.92683-1.561-7.21951-3.46342,2.34146.09756,5.561.04878,8.29268a3.35655,3.35655,0,0,0,1.5122-1.07317h0"/>
          <path id="path160" class="cls-5" d="M174.24391,76.29268c1.17073-1.95122-1.65854-4.73171-2.439-6.92683-3.12194,2.78049.73171,5.41464.97561,8.09757a3.05709,3.05709,0,0,0,1.46342-1.17073h0"/>
          <path id="path162" class="cls-5" d="M175.80488,73.31707c.92682-2.09756-2.29269-4.53658-3.2683-6.63414-2.82927,3.07317,1.41463,5.36585,1.95122,8.04878a3.93529,3.93529,0,0,0,1.31708-1.41464h0"/>
          <path id="path164" class="cls-5" d="M176.9756,70.2439c.73171-2.14634-2.82926-4.29268-4.14634-6.2439-2.34146,3.46342,2.14634,5.12195,3.0244,7.70732a3.044,3.044,0,0,0,1.12194-1.46342h0"/>
          <path id="path166" class="cls-5" d="M177.75609,66.87805c.39024-2.2439-3.26829-3.80488-4.97561-5.41463-1.46342,3.90243,2.82926,4.63414,4.09756,7.02439a3.31721,3.31721,0,0,0,.878-1.60976h0"/>
          <path id="path168" class="cls-5" d="M178.04878,63.41463c.04878-2.29268-4-3.21951-6.04878-4.34146-.439,4.14635,3.90244,3.95122,5.36586,6.2439a3.142,3.142,0,0,0,.68292-1.90244h0"/>
          <path id="path170" class="cls-5" d="M177.60976,59.5122c-.58537-2.19513-4.78048-2-6.92684-2.87805.04879,4.19512,4.87805,3.17073,6.78049,5.122a3.62485,3.62485,0,0,0,.14633-2.2439h0"/>
          <path id="path172" class="cls-5" d="M176.29268,56.04878C175.26829,54,171.36585,55.02439,169.07317,54.439c.58538,4.14635,5.17074,2.34147,7.5122,3.70732a3.26675,3.26675,0,0,0-.29269-2.09756h0"/>
          <path id="path174" class="cls-5" d="M174.63415,53.21951c-1.2683-1.90244-5.12195-.4878-7.36586-.97561.73171,4.09756,5.26829,1.85366,7.80488,2.78049a3.18473,3.18473,0,0,0-.439-1.80488h0"/>
          <path id="path176" class="cls-5" d="M172.58536,50.53658c-1.46341-1.761-4.92683,0-7.26829-.29268,1.12195,4,5.36585,1.41464,8,2.09756a3.42748,3.42748,0,0,0-.73171-1.80487h0"/>
          <path id="path178" class="cls-5" d="M170.53659,48.18537c-1.561-1.66342-4.97562.2878-7.31707.10243,1.26829,3.9561,5.41463,1.12683,8.09756,1.61464a3.13366,3.13366,0,0,0-.78049-1.71707h0"/>
          <path id="path180" class="cls-5" d="M168.39025,46c-1.65853-1.59512-4.97562.51707-7.31707.4,1.36584,3.94147,5.41463.89269,8.14634,1.21951A3.67378,3.67378,0,0,0,168.39025,46h0"/>
          <path id="path182" class="cls-5" d="M166.14634,43.92683c-1.70731-1.53659-4.97561.70732-7.31707.67317,1.5122,3.87805,5.46342.678,8.19512.8878a3.45075,3.45075,0,0,0-.87805-1.561h0"/>
          <path id="path184" class="cls-5" d="M163.85366,41.9561c-1.7561-1.48781-4.97562.84878-7.31708.89756,1.65854,3.81951,5.41464.522,8.14635.5561a3.026,3.026,0,0,0-.82927-1.45367h0"/>
          <path id="path186" class="cls-5" d="M161.46341,40.06341c-1.80488-1.42927-4.92683,1.00976-7.26829,1.11708,1.75611,3.77561,5.41464.36585,8.14635.30731a3.30663,3.30663,0,0,0-.878-1.42439h0"/>
          <path id="path188" class="cls-5" d="M158.63415,38.27805c-1.7561-1.42439-2.39024.23415-4.68293.34146-.439,4.69756,1.90244,1.36586,5.561,1.00976a2.67527,2.67527,0,0,0-.87805-1.35122h0"/>
        </g>
        <rect id="rect190" class="cls-10" x="147.12195" y="44.58049" width="6.65853" height="11.14146"/>
        <g id="g192">
          <path id="path194" class="cls-11" d="M106.82927,125.561c-5.46341,15.21952-.68293,25.75609-.68293,25.75609l23.90244,2.29269"/>
          <path id="path196" class="cls-11" d="M186.82927,154.78048c3.02439-4.82926,9.65853-18.439,7.17074-28.39025a.60009.60009,0,0,0-.09757-.2439S177.46341,136,173.31707,152.19511"/>
          <path id="path198" class="cls-11" d="M180.92683,153.122c2.97561-3.07317,10.68292-17.02439,12-26.14635"/>
        </g>
      </g>
    </g>
  </g>
</Logo>
    )
}
export default NavySecretary
