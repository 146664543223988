import React from 'react'
import {Row, Col } from 'react-bootstrap'
import CorporateDonateModalButton from './CorporateDonateModalButton'

const CorporateTab = () => (
<Row className="py-4">
                        <Col>
                          <CorporateDonateModalButton />
                        </Col>
</Row>
)

export default CorporateTab