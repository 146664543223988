import React, { useState } from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Row, Col, Button } from "react-bootstrap"
import CivicDonationModal from "./CivicDonationModal"
import CivicModal from "./CivicModal"
import styled from "styled-components"

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media(min-width: 1024px) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  .gridContainer { grid-area: 1 / 1 / 3 / 4; }
  .starGarnet { grid-area: 1 / 1 / 3 / 2; }
  .opal { grid-area: 1 / 2 / 2 / 3; }
  .jasper { grid-area: 1 / 3 / 2 / 4; }
  .jade { grid-area: 2 / 2 / 3 / 3; }
  .topaz { grid-area: 2 / 3 / 3 / 4; }  }
`;

const DonateBox = styled.div`
  height: 100%;
  padding: 15px;
  background-color: white;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
`;

const Prizes = styled.div`
  margin-top: 1rem;
`;
const DonateButton = styled.div`
  margin-top: auto;
`;


function Modal(props) {
  const [modalShow, setModalShow] = useState(false)
  const [IndividualModalCheckShow, setIndividualModalCheckShow] = useState(false)
  const [amount, setAmount] = useState(0)
  const [type, setType] = useState('')

  function showModalAmount() {
    setModalShow(true)
    setAmount(props.amount)
  }
  return (
    <>
      <Button onClick={e => showModalAmount(e)} className="ussIdahoButton btn-block">
        Donate ${props.amount / 100}
      </Button>
      <CivicDonationModal
        rank={props.rank}
        amount={props.amount}
        type={props.type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}




const DonateModalButton = () => {
return (
  <>
      <GridContainer>
  <div class="gridContainer"></div>
  <div class="starGarnet">
  <DonateBox>
          <div className="supporterTitle" style={{backgroundColor: '#7030A0'}}>
            <h3 className="supporterLevels text-center">Star Garnet</h3>
            <h4 className="supporterPrice text-center">$2,500</h4>
          </div>
          <div>
            <ul className="supporterPrize" style={{marginTop: '20px'}}>
    <li>2 Reserved VIP seats at the commissioning		</li>
		
    <li>2 Complimentary tickets to the Chairman's Banquet the evening prior to the commissioning		</li>
        
    <li>2 Complimentary tickets to the VIP Breakfast the morning of the commissioning		</li>
        
    <li>Quarter-page advertisement in Commemorative Commissioning Program		</li>
        
    <li>Company or Civic Logo and Name on USS IDAHO Commissioning Committee Website Donor page linked to your Website		</li>
        
    <li>Invitations to USS IDAHO events in the state		</li>
        
    <li>2 Challenge Coins		</li>
        
    <li>2 USS IDAHO SSN 799 Ballcaps		</li>
        
    <li>Set of commemorative Coins		</li>
        
    <li>2 Commemorative Polo Shirts		</li>
        
    <li>2 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>4 USS IDAHO Lapel Pins		</li>
        
    <li>Honorary Plank Owner Plaque		</li>
            </ul>
          </div>
        
          <div style={{marginTop: 'auto'}}>
            <Modal title="Star Garnet" amount={250000} type="Small Business/Civc" />
          </div>
        </DonateBox>
  </div>
  <div class="opal">
  <DonateBox>
          <div className="supporterTitle" style={{backgroundColor: '#DEEBF7'}}>
            <h3 className="supporterLevels text-center" style={{color: "#3E597F"}}>Opal</h3>
            <h4 className="supporterPrice text-center" style={{color: "#3E597F"}}>$1,000</h4>
          </div>
          <Prizes>
            <ul className="supporterPrize">
    <li>Company or Civic Logo and Name on USS IDAHO Commissioning Committee Website Donor page linked to your Website		</li>
		
    <li>Invitations to USS IDAHO events in the state		</li>
        
    <li>2 USS IDAHO SSN 799 Ballcaps		</li>
        
    <li>2 Commemorative Polo Shirts		</li>
        
    <li>USS IDAHO SSN 799 License Plate Frame		</li>
        
    <li>2 USS IDAHO Lapel Pins		</li>
        
    <li>Honorary Plank Owner Certificate		</li>
            </ul>
          </Prizes>
          <div>
            <Modal title="Opal" amount={100000} type="Small Business/Civc" />
          </div>
        </DonateBox>
  </div>
  <div class="jasper">
  <DonateBox>
          <div className="supporterTitle" style={{backgroundColor: '#C5E0B4'}}>
            <h3 className="supporterLevels" style={{color: "#3E597F"}}>Jasper</h3>
            <h4 className="supporterPrice" style={{color: "#3E597F"}}>$500</h4>
          </div>
          <Prizes>
            <ul className="supporterPrize">
    <li>Company / Civic Organization Name on USS IDAHO Commissioning Committee Website Donor page linked to corporate Website		</li>
		
    <li>USS IDAHO SSN 799 Ballcap		</li>
        
    <li>Commemorative Polo Shirt		</li>
        
    <li>8 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>USS IDAHO Lapel Pin		</li>
        
    <li>Honorary Plank Owner Certificate		</li>
            </ul>
            </Prizes>
          <DonateButton>
            <Modal title="Jasper" amount={50000} type="Small Business/Civc" />
            </DonateButton>
        </DonateBox>
  </div>
  <div class="jade">
  <DonateBox>
          <div className="supporterTitle" style={{backgroundColor: '#B4C7E7'}}>
            <h3 className="supporterLevels" style={{color: "#3E597F"}}>Jade</h3>
            <h4 className="supporterPrice" style={{color: "#3E597F"}}>$250</h4>
          </div>
          <Prizes>
            <ul className="supporterPrize">
    <li>Company / Civic Organization Name on USS IDAHO Commissioning Committee Website Donor page linked to corporate Website		</li>
		
    <li>USS IDAHO SSN 799 Ballcap		</li>
        
    <li>6 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>USS IDAHO Lapel Pin		</li>
        
    <li>Honorary Plank Owner Certificate		</li>
            </ul>
            </Prizes>
          <DonateButton>
            <Modal title="Silver" amount={25000} type="Small Business/Civc" />
          </DonateButton>
        </DonateBox>
  </div>
  <div class="topaz">
  <DonateBox>
          <div className="supporterTitle" style={{backgroundColor: '#FFE699'}}>
            <h3 className="supporterLevels" style={{color: "#3E597F"}}>Topaz</h3>
            <h4 className="supporterPrice" style={{color: "#3E597F"}}>$100</h4>
          </div>
          <Prizes>
            <ul className="supporterPrize">
    <li>Company / Civic Organization Name on USS IDAHO Commissioning Committee Website Donor page linked to corporate Website		</li>
		
    <li>6 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>USS IDAHO Lapel Pin		</li>
        
    <li>Honorary Plank Owner Certificate		</li>
            </ul>
            </Prizes>
          <DonateButton>
            <Modal title="Topaz" amount={10000} type="Small Business/Civc"/>
            </DonateButton>
        </DonateBox>
  </div>
</GridContainer>
  </>
)
  }

export default DonateModalButton
