import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import InjectedElementsForm from "./StripeElementsForm";
import CheckoutForm from "./CheckoutForm";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
// Sign in to see examples pre-filled with your key.
//const stripePromise = api.getPublicStripeKey().then(key => loadStripe(key));
// Test key:
//const promise = loadStripe('pk_test_51HdrtPL7wW70MYYXgccg8QNqQeTFUGoV10sjuzE6dCQitJwrD45PlIzVPiTxxIZ02jnj9HOQDP4NbAp42ahwJJpl00Zs2IIXlo')

// Live key:
const promise = loadStripe('pk_live_51HdrtPL7wW70MYYXw6fa2omd8GxSURWd6TUL2pjNh1O4MKwmXO8yPnxJkkr0b5F42EsdYPO2z4bLwHgRh3TY6Fgw00uYfUh8dV')

export default function DonationWrapper(props) {
    return (
        <div>
            <Elements stripe={promise}>
                
                <CheckoutForm amount={props.amount} rank={props.rank} type={props.type}/>
                
                {/* <InjectedElementsForm /> */}
            </Elements>
        </div>
    )
}
