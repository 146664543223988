import React from 'react'
import {Row, Col } from 'react-bootstrap'
import DonateModalButton from './DonateModalButton'

const IndividualTab = () => (
<>
<Row className="py-4">
                        <Col>
                          <DonateModalButton />
                        </Col>
</Row>
                    
</>
)

export default IndividualTab