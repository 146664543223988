import React from 'react';
import { Link } from 'gatsby'
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js'
import { Form, InputGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import AddressSection from './AddressSection'

import api from '../../api'

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
          color: "#32325d",
          fontFamily: '"Open Sans", sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      }, 
}

class StripeElementsForm extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        payment_method_id: "",
        amount: 100,
        currency: "usd",
        clientSecret: null,
        error: null,
        disabled: false,
        succeeded: false,
        processing: false,
        receipt_email: null,
        selectAmount: null,
        metadata: {
          address: '',
          name: '',
          city: '',
          state: '',
          zip: '',
          type: '',
          phoneType: '',
          //selectedOption: null,
        },
    
      }
     // this.handleData = this.handleData.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
  
    setAmount(event) {
      console.log(event.target.value);
      // Conver the string to an integer to pass to API
      const donationAmount = parseInt(event.target.value)
      console.log(donationAmount, typeof(donationAmount));
      this.setState({
        amount: donationAmount,
      })
    } 
  
      // Pass data from AddressSection component to parent
      updateData = (target, value) => {
        this.setState({
          [target]: value
        });
      }
  
      handleChange = selectedOption => {
        this.setState({ selectedOption });
        console.log(selectedOption);
      }
  
  
  
    async handleSubmit(ev) {
      ev.preventDefault()
      const {stripe, elements} = this.props;
  
      api
        .createPaymentIntent({
          payment_method_types: ["card"],
          amount: this.state.amount,
          receipt_email: this.state.receipt_email,
          metadata: {
            name: this.state.name,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            email: this.state.receipt_email,
            type: this.state.type
            //selectedOption: this.state.selectedOption.value,
          }
          //customer: this.state.customer,
        })
        .then(clientSecret => {
          this.setState({
            clientSecret: clientSecret,
            disabled: true,
            processing: true,
          })
  
          // confirmCardPayment seems to be required - not handleCardPayment
          this.props.stripe.confirmCardPayment(this.state.clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                email: this.state.email,
                name: this.state.name,
              },
            }
          }).then(payload => {
                if (payload.error) {
                  this.setState({
                    error: `Payment failed: ${payload.error.message}`,
                    disabled: false,
                    processing: false,
                  })
                  console.log("[error]", payload.error)
                } else {
                  this.setState({
                    processing: false,
                    succeeded: true,
                    error: "",
                    metadata: payload.paymentIntent,
                  })
                  console.log("[PaymentIntent]", payload.paymentIntent)
                }
              })
        
          // Step 2: Use clientSecret from PaymentIntent to handle payment in stripe.handleCardPayment() call
          // this.props.stripe
          //   .handleCardPayment(this.state.clientSecret)
          //   .then(payload => {
          //     if (payload.error) {
          //       this.setState({
          //         error: `Payment failed: ${payload.error.message}`,
          //         disabled: false,
          //         processing: false,
          //       })
          //       console.log("[error]", payload.error)
          //     } else {
          //       this.setState({
          //         processing: false,
          //         succeeded: true,
          //         error: "",
          //         metadata: payload.paymentIntent,
          //       })
          //       console.log("[PaymentIntent]", payload.paymentIntent)
          //     }
          //   })
        })
        .catch(err => {
          this.setState({ error: err.message })
        })
    }
  
    renderSuccess() {
      return (
        <div className="sr-field-success message">
          <h2>Thank you for your donation!</h2>
          <p>Please check your email for a donation receipt, and please don't forget to <Link to="">sign up</Link> for our newsletter!</p>
  
          {/* <p>View PaymentIntent response:</p>
          <pre className="sr-callout">
            <code>{JSON.stringify(this.state.metadata, null, 2)}</code>
          </pre> */}
        </div>
      )
    }
  
  
  
    renderForm() {
      var style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      }
  
      const { selectedOption } = this.state;
  
      return (
        <Form onSubmit={this.handleSubmit}>
          <div className="sr-combo-inputs">
            {/* <div className="sr-combo-inputs-row">
              <input
                type="text"
                id="name"
                placeholder="Name"
                autoComplete="cardholder"
                className="sr-input"
              />
            </div> */}
  
            <AddressSection amount={this.state.amount} updateData={this.updateData}/>
            <div className="row mb-4" >
                  {/* <div className="col-sm-4">
                    <div className="form-group">
                      <label>Why did you donate?</label>
                      <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                      />
                      </div>
                  </div> */}
                  <div className="col-sm-4 mb-2" >
                  <div onChange={event => this.setAmount(event)}>
                    <label style={{marginRight: '10px'}}>Donation Amount</label>
                    {/* <ToggleButtonGroup type="radio" name="donation" defaultValue={[100]}> */}
                    <ToggleButtonGroup type="radio" name="donation" defaultValue={[100]}>
                      <ToggleButton value={25} name="donationAmount" className="btn-action">$25</ToggleButton>
                      <ToggleButton value={50} name="donationAmount" className="btn-action">$50</ToggleButton>
                      <ToggleButton value={100} name="donationAmount" className="btn-action">$100</ToggleButton>
                      <ToggleButton value={250} name="donationAmount" className="btn-action">$250</ToggleButton>
                    </ToggleButtonGroup>
                    </div>
                    </div>
                    <div className="col-sm-4 mb-2" onChange={event => this.setAmount(event)} >
                      <Form.Group>
                    <Form.Label>Other Amount</Form.Label>
                    <InputGroup>
                    <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
              </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      name="donationAmount" placeholder="Whole dollar amount please!"/>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  
                </div>
  
            <div className="sr-combo-inputs-row">
              <label>Payment Information</label>
              <CardElement
              options={CARD_ELEMENT_OPTIONS} 
              className="sr-input sr-card-element MyCardElement"
            
              />
            </div>
          </div>
  
          {this.state.error && (
            <div className="message sr-field-error">{this.state.error}</div>
          )}
  
          {!this.state.succeeded && (
            <button
              className="donateButton btn"
              data-secret="{{ACTUAL_SECRET}}"
              disabled={this.state.disabled}
            >
              { this.state.processing
                ? "Processing…"
                : `Donate $${this.state.amount}`}
            </button>
          )}
        </Form>
      )
    }
  
    render() {
      return (
        <div className="checkout-form">
          <div className="sr-payment-form">
            <div className="sr-form-row" />
            {this.state.succeeded && this.renderSuccess()}
            {!this.state.succeeded && this.renderForm()}
          </div>
        </div>
      )
    }
  }
  
    const InjectedElementsForm = () => {
      return (
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <StripeElementsForm elements={elements} stripe={stripe} />
          )}
        </ElementsConsumer>
      );
    };
  
    export default InjectedElementsForm

