import React from 'react'
import { Row, Col, Form} from 'react-bootstrap'


class AddressSection extends React.Component {
  constructor(props) {
    super(props);
    //this.handleChange = this.handleChange.bind(this);
    this.state = {
      receipt_email: '', 
      customer: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    }
  }

  // handleChange(event) {
  //   this.props.addressSectionHandler(this.state.receipt_email);
  //   this.setState({
  //     receipt_email: event.target.value,
  //     customer: event.target.value,
  //   });
  //   console.log(event.target.value)
  // }

  render() {
    return (
      <>
    
<div style={{ marginBottom: "20px" }}>
            <Form.Group>
              <Row>
                <Col sm={6} className="mb-2">
                  <Form.Label>Your Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter name"
                    // pass the value as props up to StripeCheckoutForm
                    onChange={e => this.props.updateData('name', e.target.value)}
                  />
                </Col>
                <Col sm={6} className="mb-2">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    //name={this.state.receipt_email} 
                    onChange={e => this.props.updateData('receipt_email', e.target.value)}
                    placeholder="Enter email"
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col sm={4} className="mb-2">
                  <Form.Label>Your Mailing Address</Form.Label>
                  <Form.Control
                    type="text"
                    id="address"
                    name="address"
                    onChange={e => this.props.updateData('address', e.target.value)}
                    placeholder="Enter address"
                  />
                </Col>
                <Col sm={4} className="mb-2">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="city"
                    id="city"
                    name="city"
                    onChange={e => this.props.updateData('city', e.target.value)}
                    placeholder="Enter city"
                  />
                </Col>
                <Col sm={2} className="mb-2">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="state"
                    id="state"
                    name="state"
                    onChange={e => this.props.updateData('state', e.target.value)}
                    placeholder="Enter state"
                  />
                </Col>
                <Col sm={2} className="mb-2">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    type="zip"
                    id="zip"
                    name="zip"
                    onChange={e => this.props.updateData('zip', e.target.value)}
                    placeholder="Enter zip"
                  />
                </Col>
              </Row>
            </Form.Group>
          </div>

          {/* <div>
            <Form.Group>
              <fieldset>
                <Form.Group as={Row}>
                  <Form.Label as="legend" column sm={2}>
                    Donation Amount:
                  </Form.Label>
                  <Col sm={2}>
                    
                  </Col>
                </Form.Group>
              </fieldset>
            </Form.Group>
          </div> */}
          </>
)
}
}

export default AddressSection;