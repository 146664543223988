import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'

import CorporateDonorForm from './CorporateDonorForm/CorporateDonorForm'

function DonationmodalCheck(props) {
  //console.log(props.amount)
   return (
        <>
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            amount={props.amount}
            
          ><Modal.Header closeButton>
          <Modal.Title>
          How to donate amounts of $5,000 or more
          </Modal.Title>
          </Modal.Header>
            <Modal.Body>
            <div>
              <p>Donations to the USS IDAHO Commissioning Foundation are eligible for Federal Tax-Exempt under IRC Section 501(c)(3) (EIN 84-5147328).</p>
      <h3 style={{fontSize: '1.25rem', textAlign: 'center'}}>Checks may be made payable to:</h3>
      <Row>
        <Col sm={6}>
        <p><strong>USS IDAHO Commissioning Foundation</strong><br />
(or USSICF)
</p>
        </Col>
        <Col sm={6}>
        <p>
<strong>and mailed to:</strong><br />
USS IDAHO Commissioning Foundation<br />
c/o Pulliam Associates<br />
7235 W Emerald St Suite A <br />
Boise ID  83704</p>
        </Col>
      </Row>
      <hr />
      <h3 style={{fontSize: '1.25rem', textAlign: 'center'}}>Or, fill out the form below and we'll be in touch:</h3>
    </div>
            {/* Donate ${props.amount / 100} */}
            
            <CorporateDonorForm amount={props.amount}/>
            </Modal.Body>  
            </Modal>      
        </>
    )
}

export default DonationmodalCheck
