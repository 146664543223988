import React, { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import MaskedInput from 'react-input-mask'
import "./StripeCheckout.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLevelUpAlt } from "@fortawesome/pro-solid-svg-icons"

const normalizePhoneNumber = (value) => {
  return value.replace(/\s/g, "").match(/.{1,4}/g)?.join(" ").substr(0, 13) || ""
}

export default function CheckoutForm(props) {
  const [message, setMessage] = useState("")

  const {register, formState: { isValid, isSubmitted, errors }, reset, watch, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
});
  const [tel, setTel] = useState('7');  

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [amount, setAmount] = useState(props.amount)
  const [publishName, setPublishName] = useState("")
  const stripe = useStripe();
  const elements = useElements();

  

  const [value, setValue] = useState();

  const watchObject = watch()
  const donorName = watchObject.donorName;
  const address = watchObject.address;
  const city = watchObject.city;
  const state = watchObject.state;
  const email = watchObject.email;
  const zip = watchObject.zip;
  const phone = watchObject.phone;
  const publishedName = watchObject.publishedName;
  const publishedTown = watchObject.publishedTown;
  const title = props.title
  const type = props.type
  const phoneType = watchObject.phoneType


  const cardStyle = {
    style: {
      base: {
        color: "#212529",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#212529"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleCustomAmount = async (event) => {
    const newAmount =  parseInt(event.target.value);
    console.log(typeof(newAmount));
    setAmount(newAmount * 100);
  }

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    window
      .fetch("/.netlify/functions/charge", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          amount,
          receipt_email: email,
            metadata: {
             donorName: donorName,
             address: address,
             city: city,
             state: state,
             zip: zip,
             email: email,
             publishedName: publishedName,
             publishedTown: publishedTown,
             type: type,
             phone: phone,
             phoneType: phoneType
            }
          }
        )
      })
      .then(res => {
        return res.json();
        
      })
      .then(data => {
        //console.log(data)
        setClientSecret(data.clientSecret);

        //console.log(`clientSecret: ${clientSecret}`)
        
        return stripe.confirmCardPayment(data.clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement)
          }
        })
        
        .then((paymentResult) => {
          if (paymentResult.error) {
            setError(`Payment failed: ${paymentResult.error.message}`);
            setProcessing(false);
          } else {
            if (paymentResult.paymentIntent.status === 'succeeded') {
              setError(null);
              setProcessing(false);
              setSucceeded(true);
              reset();
              //elements.getElement(CardElement).clear();
            }
          }
        })
      })
  };

  const DonationSuccessMessage = () => (
    <div className="donation-success-message">
      <h2>Thank you for your donation!</h2>
      <p>Please check your email for a donation acknowledgement, and please don't forget to <a href="">sign up</a> for our newsletter!</p>
    </div>
)
console.log(watchObject)
  return (
    <>
    {succeeded ? <DonationSuccessMessage /> : 
        <div>
        <h3 style={{fontSize: '1.25rem', textAlign: 'center'}}> be made payable to:</h3>
        <Row>
          <Col sm={6}>
          <p><strong>USS IDAHO Commissioning Foundation</strong><br />
  (or USSICF)
  </p>
          </Col>
          <Col sm={6}>
          <p>
  <strong>and mailed to:</strong><br />
  USS IDAHO Commissioning Foundation<br />
c/o Pulliam Associates<br />
7235 W Emerald St Suite A <br />
Boise ID  83704</p>
          </Col>
        </Row>
        <hr />
        <h3 style={{fontSize: '1.25rem', textAlign: 'center'}}>Or, donate securely by credit card with the form below:</h3>
        <h2 style={{textAlign: 'center'}}>{props.rank}</h2>
      <h1>Donate ${amount / 100}</h1>
      <p style={{display: 'none'}}>Type: {props.type}</p>
      {/* <Row>
        <Col>
        <Button className="ussIdahoButton" onClick={(e) => setAmount(2500)}>
        Donate $25
      </Button>
        </Col>
        <Col>
        <Button className="ussIdahoButton" onClick={(e) => setAmount(5000)}>
        Donate $50
      </Button>
      </Col>
      <Col>
      <Button className="ussIdahoButton" onClick={(e) => setAmount(10000)}>
        Donate $100
      </Button>
        </Col>
        <Col>
      <Button className="ussIdahoButton"  onClick={(e) => setAmount(25000)}>
        Donate $250
      </Button>
        </Col>
      </Row> */}

    <form id="payment-form" onSubmit={handleSubmit} onChange={handleChange}>
    <div style={{ marginBottom: "20px" }}>
        <Form.Group>
          <Row>
            <Col sm={12}>
            { props.rank === "Shipmate" && (
              <>
              <Form.Label>Choose donation amount:</Form.Label>
              <input 
                type="number"
                
                default="50"
                placeholder="50"
                min="1"
                max="99"
                step="1"
                {...register("amount", {
                  min: 1,
                  max: 99,
                  pattern: {
                    value: /^(0?[1-9]|[1-9][0-9])$/, 
                    message: 'Please enter a whole number between 1 and 99'
                  }  
                  })} 
                onChange={handleCustomAmount}
              />
                        {" "}{errors.amount && <span className="formError">{errors.amount.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }   
              </>
            )}
            </Col>
          </Row>
              <Row>
                <Col sm={6} className="mb-2">
                  <Form.Label>Your Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="donorName"
                    name="donorName"
                    placeholder="Enter full name"
                    {...register('donorName')}
                    
                  />
                </Col>
                <Col sm={6} className="mb-2">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    name="email"
                    {...register("email", { required: "Enter your e-mail",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Enter a valid e-mail address",
            }, })}
          />
          {" "}{errors.email && <span className="formError">{errors.email.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }            
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col sm={4} className="mb-2">
                  <Form.Label>Your Mailing Address</Form.Label>
                  <Form.Control
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Enter address"
                    {...register('address')}
                          
                  />
                </Col>
                <Col sm={4} className="mb-2">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="city"
                    id="city"
                    name="city"
                    placeholder="Enter city"
                    {...register('city')}
                    
                  />
                </Col>
                <Col sm={2} className="mb-2">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="state"
                    id="state"
                    name="state"
                    placeholder="Enter state"
                    {...register('state')}
                    
                  />
                </Col>
                <Col sm={2} className="mb-2">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    type="zip"
                    id="zip"
                    name="zip"
                    placeholder="Enter zip"
                    {...register('zip')}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
              <Col sm={4} className="mb-2">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="(000) 000-0000"
                    {...register("phone", { 
                      pattern: {
                      value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                      message: "Please enter a valid phone number"
                    },
                })}                
                  />
                  {" "}{errors.phone && <span className="formError">{errors.phone.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
                </Col>
              
                <Col>
                    <Form.Label as="legend">Type</Form.Label>
                    <Form.Check
                      inline
                      type="radio"
                      label="Mobile (preferred)"
                      value="mobile"
                      name="phoneType"
                      id="mobile"
                      defaultChecked
                      {...register('phoneType')}
                      
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Work"
                      value="work"
                      name="phoneType"
                      id="work"
                      {...register('phoneType')}
                      
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Home"
                      value="home"
                      name="phoneType"
                      id="home"
                      {...register('phoneType')}
                      
                    />
                </Col>
              </Row>
            </Form.Group>
            <fieldset>
    <Form.Group as={Row}>
      {/* <Form.Label as="legend" column sm={12}>
        Name you'd like published on our website:
      </Form.Label> */}
      <Col sm={6}>
        <Form.Check
          type="radio"
          label="Please publish my/our name(s) on the website."
          value="Yes"
          name="publishName"
          id="publishName1"
          defaultChecked
          {...register('publishName')}
          
          onClick={() => setPublishName("Yes")}
        />
        <Form.Check
          type="radio"
          label="Please make my donation anonymous."
          value="No"
          name="publishName"
          id="publishName2"
          {...register('publishName')}
          
          onClick={() => setPublishName("No")}  
        />
      </Col>
      <Col>
      { publishName === "No" ? null : <Form.Group>
                  <Form.Control
                    type="text"
                    id="publishedName"
                    name="publishedName"
                    placeholder="Enter name(s) you'd like published"
                    {...register('publishedName')}
                    
                  />
                  <Form.Control
                    type="text"
                    id="publishedTown"
                    name="publishedTown"
                    placeholder="Hometown"
                    {...register('publishedTown')}
                    
                  />
      </Form.Group>
      }
      </Col>
    </Form.Group>
  </fieldset>
      
  
          </div>  
      <CardElement id="card-element" options={cardStyle} />
      <button className="stripeButton" 
        disabled={processing || disabled || succeeded || !isValid}
        id="submit"
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            `Donate $${amount / 100}`
          )}
        </span>
      </button>
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      
      <p className={succeeded ? "result-message" : "result-message hidden"}>
        Thank you for your donation!
      </p>
    </form>
    </div>
 }
    </>
  );

}