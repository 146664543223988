import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Row, Col, Button } from "react-bootstrap"
import DonationModal from "./DonationModal"
import IndividualModal from "./IndividualModal"

import CommanderAndChief from "../SupportersPage/Individuals/CommanderAndChief"
import NavySecretary from "../SupportersPage/Individuals/NavySecretary"
import FleetAdmiral from "../SupportersPage/Individuals/FleetAdmiral"
import Admiral from "../SupportersPage/Individuals/Admiral"
import RearAdmiral from "../SupportersPage/Individuals/RearAdmiral"
import Captain from "../SupportersPage/Individuals/Captain"

function Modal(props) {
  const [modalShow, setModalShow] = useState(false)
  const [IndividualModalCheckShow, setIndividualModalCheckShow] = useState(false)
  const [amount, setAmount] = useState(0)
  const [type, setType] = useState('')

  function showModalAmount() {
    setModalShow(true)
    setAmount(props.amount)
  }
  return (
    <>
      <Button onClick={e => showModalAmount(e)} className="ussIdahoButton btn-block">
        Donate {props.rank === "Shipmate" ? ('Now') : ( `$${props.amount / 100} `) }
      </Button>
      <DonationModal
        rank={props.rank}
        amount={props.amount}
        type={props.type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}

function IndividualModalCheck(props) {
  const [modalShow, setModalShow] = useState(false)
  const [amount, setAmount] = useState(0)
  const [type, setType] = useState('')

  function showModalAmount() {
    setModalShow(true)
    setAmount(props.amount)
  }
  return (
    <>
      <Button onClick={e => showModalAmount(e)} className="ussIdahoButton btn-block">
        Donate ${(props.amount / 100).toLocaleString()}
      </Button>
      <IndividualModal
        amount={props.amount}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}


const DonateModalButton = () => {
  const data = useStaticQuery(graphql`
  {
    admiral: file(relativePath: { eq: "admiral@2x.png" }) {
      childImageSharp {
        fixed(width: 150, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    viceAdmiral: file(relativePath: { eq: "vice-admiral@2x.png" }) {
      childImageSharp {
        fixed(width: 120, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    commander: file(relativePath: { eq: "commander@2x.png" }) {
      childImageSharp {
        fixed(width: 80, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
      ltcommander: file(relativePath: { eq: "ltCommander@2x.png" }) {
      childImageSharp {
        fixed(width: 80, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lieutenant: file(relativePath: { eq: "Lieutenant@2x.png" }) {
      childImageSharp {
        fixed(width: 80, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ensign: file(relativePath: { eq: "Ensign@2x.png" }) {
      childImageSharp {
        fixed(width: 32, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cpo: file(relativePath: { eq: "ChiefPettyOfficer.png" }) {
      childImageSharp {
        fixed(width: 65, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    shipmate: file(relativePath: { eq: "shipmate.png" }) {
      childImageSharp {
        fixed(width: 35, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`)
return (
  <>
    <Row className="mb-4 text-center">
      {/* <Col md={4} className="my-2">
    <Modal 
        amount={2500}
    />
    </Col> */}
      <Col md={6} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
            <div style={{padding: '10px'}}>
            <Img fixed={data.admiral.childImageSharp.fixed} alt="Admiral" />
            {/* <Admiral style={{width: '80px', height: '80px'}}/> */}
            </div>
            <h3 className="supporterLevels">Admiral</h3>
            <h4 className="supporterPrice">$25,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
    <li>Museum quality 1/192 Scale Model of USS IDAHO SSN 799 to display		</li>
		
    <li>Appx 12" x 18" Framed US and IDAHO Flags flown from USS IDAHO		</li>
        
    <li>Commemorative Ship's Bell		</li>
        
    <li>6 Reserved VIP seats at the commissioning		</li>
        
    <li>6 Complimentary tickets to the Chairman's Banquet the evening prior to the commissioning		</li>
        
    <li>6 Complimentary tickets to the VIP Breakfast the morning of the commissioning		</li>
        
    <li>Recognition on USS IDAHO Commissioning Committee Website		</li>
        
    <li>Invitations to USS IDAHO events in the state		</li>
        
    <li>Set of Commemorative Coins		</li>
        
    <li>6 Challenge Coins		</li>
        
    <li>6 Commemorative Polo Shirts		</li>
        
    <li>12 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>10 USS IDAHO Lapel Pins		</li>
        
    <li>2 USS IDAHO SSN 799 Ball Caps		</li>
        
    <li>Honorary Plank Owner Plaque		</li>
            </ul>
          </div>
          <div>
            <IndividualModalCheck title="Admiral" amount={2500000} type="individual" />
          </div>
        </div>
      </Col>
      {/* <Col md={4} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
          <div style={{padding: '10px'}}>
            <NavySecretary style={{width: '80px', height: '80px'}}/>
            </div>
            <h3 className="supporterLevels">Navy Secretary</h3>
            <h4 className="supporterPrice">$20,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
              <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
              <li>Aliquam tincidunt mauris eu risus.</li>
              <li>Vestibulum auctor dapibus neque.</li>
              <li>Nunc dignissim risus id metus.</li>
              <li>Cras ornare tristique elit.</li>
              <li>Vivamus vestibulum ntulla nec ante.</li>
              <li>Praesent placerat risus quis eros.</li>
              <li>Fusce pellentesque suscipit nibh.</li>
              <li>Integer vitae libero ac risus egestas placerat.</li>
              <li>Vestibulum commodo felis quis tortor.</li>
              <li>Ut aliquam sollicitudin leo.</li>
            </ul>
          </div>
          <div>
            <IndividualModalCheck amount={2000000} type="individual" />
          </div>
        </div>
      </Col>
      <Col md={4} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
          <div style={{padding: '10px'}}>
            <FleetAdmiral style={{width: '80px', height: '80px'}}/>
            </div>
            <h3 className="supporterLevels">Fleet Admiral</h3>
            <h4 className="supporterPrice">$15,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
              <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
              <li>Aliquam tincidunt mauris eu risus.</li>
              <li>Vestibulum auctor dapibus neque.</li>
              <li>Nunc dignissim risus id metus.</li>
              <li>Cras ornare tristique elit.</li>
              <li>Vivamus vestibulum ntulla nec ante.</li>
              <li>Praesent placerat risus quis eros.</li>
              <li>Fusce pellentesque suscipit nibh.</li>
              <li>Integer vitae libero ac risus egestas placerat.</li>
              <li>Vestibulum commodo felis quis tortor.</li>
            </ul>
          </div>
          <div>
            <IndividualModalCheck amount={1500000} type="individual" />
          </div>
        </div>
      </Col> */}
      <Col md={6} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
          <div style={{padding: '10px'}}>
          <Img fixed={data.viceAdmiral.childImageSharp.fixed} alt="Vice Admiral" />
            </div>
            <h3 className="supporterLevels">Vice Admiral</h3>
            <h4 className="supporterPrice">$10,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
    <li>4 Reserved VIP seats at the commissioning		</li>
		
    <li>4 Complimentary tickets to the Chairman's Banquet the evening prior to the commissioning		</li>
        
    <li>4 Complimentary tickets to the VIP Breakfast the morning of the commissioning		</li>
        
    <li>Recognition on USS IDAHO Commissioning Committee Website		</li>
        
    <li>Invitations to USS IDAHO events in the state		</li>
        
    <li>Set of Commemorative Coins		</li>
        
    <li>4 Challenge Coins		</li>
        
    <li>4 Commemorative Polo Shirts		</li>
        
    <li>8 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>6 USS IDAHO Lapel Pins		</li>
        
    <li>2 USS IDAHO SSN 799 Ball Caps		</li>
        
    <li>Honorary Plank Owner Plaque		</li>
            </ul>
          </div>
          <div>
            <IndividualModalCheck amount={1000000}  type="individual"/>
          </div>
        </div>
      </Col>
      </Row>
      <Row className="mb-4 text-center">
      <Col md={6} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
          <div style={{padding: '10px'}}>
            <RearAdmiral style={{width: '80px', height: '80px'}}/>
            </div>
            <h3 className="supporterLevels">Rear Admiral</h3>
            <h4 className="supporterPrice">$5,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
            <li>2 Reserved VIP seats at the commissioning		</li>
		
    <li>2 Complimentary tickets to the Chairman's Banquet the evening prior to the commissioning		</li>
        
    <li>2 Complimentary tickets to the VIP Breakfast the morning of the commissioning		</li>
        
    <li>Recognition on USS IDAHO Commissioning Committee Website		</li>
        
    <li>Invitations to USS IDAHO events in the state		</li>
        
    <li>Set of Commemorative Coins		</li>
        
    <li>2 Challenge Coin		</li>
        
    <li>2 Commemorative Polo Shirts		</li>
        
    <li>4 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>4 USS IDAHO Lapel Pins		</li>
        
    <li>2 USS IDAHO SSN 799 Ball Caps		</li>
        
    <li>Honorary Plank Owner Plaque		</li>
            </ul>
          </div>
          <div>
            <IndividualModalCheck amount={500000} type="individual" />
          </div>
        </div>
      </Col>
      <Col md={6} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
          <div style={{padding: '10px'}}>
            <Captain style={{width: '120px', height: '80px'}}/>
            </div>
            <h3 className="supporterLevels">Captain</h3>
            <h4 className="supporterPrice">$2,500</h4>
          </div>
          <div>
            <ul className="supporterPrize">
            <li>2 Reserved VIP seats at the commissioning		</li>
		
    <li>2 Complimentary tickets to the Chairman's Banquet the evening prior to the commissioning		</li>
        
    <li>2 Complimentary tickets to the VIP Breakfast the morning of the commissioning		</li>
        
    <li>Recognition on USS IDAHO Commissioning Committee Website		</li>
        
    <li>Invitations to USS IDAHO events in the state		</li>
        
    <li>2 Challenge Coins		</li>
        
    <li>2 Commemorative Polo Shirts		</li>
        
    <li>4 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>4 USS IDAHO Lapel Pins		</li>
        
    <li>2 USS IDAHO SSN 799 Ball Caps		</li>
        
    <li>Honorary Plank Owner Plaque		</li>
            </ul>
          </div>

          <div>
            <Modal rank="Captain" amount={250000} type="individual" />
          </div>
        </div>
      </Col>
    </Row>
    <Row className="mb-4 text-center">
      {/* <Col md={4} className="my-2">
    <Modal 
        amount={2500}
    />
    </Col> */}
      

      <Col md={6} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
          <div style={{padding: '10px'}}>
            <Img fixed={data.commander.childImageSharp.fixed} alt="Commander" />
            </div>
            <h3 className="supporterLevels">Commander</h3>
            <h4 className="supporterPrice">$1,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
    <li>Recognition on USS IDAHO Commissioning Committee Website		</li>
		
    <li>Invitations to USS IDAHO events in the state		</li>
        
    <li>Commemorative Polo Shirt		</li>
        
    <li>2 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>4 USS IDAHO Lapel Pins		</li>
        
    <li>2 USS IDAHO SSN 799 Ball Caps		</li>
        
    <li>Honorary Plank Owner Certificate		</li>
            </ul>
          </div>
          <div>
            <Modal rank="Commander" amount={100000} type="individual" />
          </div>
        </div>
      </Col>
      <Col md={6} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
          <div style={{padding: '10px'}}>
            <Img fixed={data.lieutenant.childImageSharp.fixed} alt="Lieutenant" />
            </div>
            <h3 className="supporterLevels">Lieutenant</h3>
            <h4 className="supporterPrice">$500</h4>
          </div>
          <div>
            <ul className="supporterPrize">
    <li>Recognition on USS IDAHO Commissioning Committee Website		</li>
		
    <li>1 Commemorative Book Mark		</li>
        
    <li>1 Commemorative Polo Shirt		</li>
        
    <li>1 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>1 USS IDAHO Lapel Pins		</li>
        
    <li>1 USS IDAHO SSN 799 Ball Caps		</li>
        
    <li>Honorary Plank Owner Certificate		</li>
            </ul>
          </div>
          <div>
            <Modal rank="Lieutenant" amount={50000} type="individual" />
          </div>
        </div>
      </Col>
      </Row>
      <Row className="mb-4 text-center">
      <Col md={6} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
          <div style={{padding: '10px'}}>
            <Img fixed={data.ensign.childImageSharp.fixed} alt="Ensign" />
            </div>
              <h3 className="supporterLevels">Ensign</h3>
              <h4 className="supporterPrice">$250</h4>
          </div>
          <div>
            <ul className="supporterPrize">
    <li>Recognition on USS IDAHO Commissioning Committee Website		</li>
		
    <li>1 Commemorative Book Mark		</li>
        
    <li>1 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>1 USS IDAHO Lapel Pins		</li>
        
    <li>1 USS IDAHO SSN 799 Ball Caps		</li>
        
    <li>Honorary Plank Owner Certificate		</li>
            </ul>
          </div>
          <div>
              <Modal
                rank="Ensign" 
                amount={25000} type="individual" />
          </div>
        </div>
      </Col>
      <Col md={6} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
          <div style={{padding: '10px'}}>
            <Img fixed={data.cpo.childImageSharp.fixed} alt="Chief Petty Officer" />
            </div>
              <h3 className="supporterLevels">Chief Petty Officer</h3>
              <h4 className="supporterPrice">$100</h4>
          </div>
          <div>
            <ul className="supporterPrize">
    <li>Recognition on USS IDAHO Commissioning Committee Website		</li>
		
    <li>1 Commemorative Book Mark		</li>
        
    <li>1 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>1 USS IDAHO Lapel Pins		</li>
        
    <li>Honorary Plank Owner Certificate		</li>
            </ul>
          </div>
          <div>
              <Modal 
                rank="Chief Petty Officer"
                amount={10000}  type="individual"/>
          </div>
        </div>
      </Col>
      </Row>
      <Row className="mb-4 text-center">

      <Col md={4} className="my-2">
        <div className="donateBox">
          <div className="supporterTitle">
          <div style={{padding: '10px'}}>
            <Img fixed={data.shipmate.childImageSharp.fixed} alt="Shipmate" />
            </div>
              <h3 className="supporterLevels">Shipmate</h3>
              <h4 className="supporterPrice">&lt; $100</h4>
          </div>
          <div>
            <ul className="supporterPrize">
    <li>1 Commemorative Book Mark		</li>
		
    <li>1 USS IDAHO SSN 799 License Plate Frame		</li>
        
    <li>1 USS IDAHO Lapel Pin		</li>
            </ul>
          </div>
          <div><Modal rank="Shipmate" amount={5000} type="individual"/></div>
        </div>
      </Col>
    </Row>
  </>
)
  }

export default DonateModalButton
