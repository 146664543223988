import React from 'react'
import {Row, Col } from 'react-bootstrap'
import CivicDonateModalButton from './CivicDonateModalButton'

const CivicTab = () => (
<Row className="py-4">
                        <Col>
                          <CivicDonateModalButton />
                        </Col>
</Row>
)

export default CivicTab