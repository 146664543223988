import React from "react";
import { Link } from "@reach/router";

// export default ({ partial=true, ...props}) => (
//   <Link
//     {...props}
//     getProps={({isCurrent, isPartiallyCurrent }) => {
//       const isActive = partial
//         ? isPartiallyCurrent
//         : isCurrent;
//       return {
//         style: {
//           color: isActive ? "red" : "blue"
//         }
//       };
//     }}
//   />
// );

const isPartiallyActive = ({
    isPartiallyCurrent
}) => {
    return isPartiallyCurrent ? { className: `active supportersNav nav-link` } : {}
}
const NavLink = props => (
    <Link getProps={isPartiallyActive} {...props} />
)

export default NavLink