import React from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import styled from "styled-components"

const Logo = styled.svg `
  width: 150px;
  height: 150px;
  .cls-1 {
        fill: #fff;
      }

      .cls-1, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-2, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-3, .cls-4, .cls-5, .cls-6, .cls-8, .cls-9 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: url(#linear-gradient);
      }

      .cls-3 {
        fill: url(#linear-gradient-2);
      }

      .cls-4 {
        fill: url(#linear-gradient-3);
      }

      .cls-5 {
        fill: url(#linear-gradient-4);
      }

      .cls-6 {
        fill: url(#linear-gradient-5);
      }

      .cls-7 {
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 0.81111px;
      }

      .cls-8 {
        fill: url(#linear-gradient-6);
      }

      .cls-9 {
        fill: url(#linear-gradient-7);
      }

      .cls-10 {
        fill: url(#linear-gradient-8);
      }

      .cls-11 {
        fill: url(#linear-gradient-9);
      }

      .cls-12 {
        fill: url(#linear-gradient-10);
      }

      .cls-13 {
        fill: url(#linear-gradient-11);
      }

      .cls-14 {
        fill: url(#linear-gradient-12);
      }

      .cls-15 {
        fill: url(#linear-gradient-13);
      }

      .cls-16 {
        fill: url(#linear-gradient-14);
      }

      .cls-17 {
        fill: url(#linear-gradient-15);
      }

      .cls-18 {
        fill: url(#linear-gradient-16);
      }

      .cls-19 {
        fill: url(#linear-gradient-17);
      }

      .cls-20 {
        fill: url(#linear-gradient-18);
      }

      .cls-21 {
        fill: url(#linear-gradient-19);
      }

      .cls-22 {
        fill: url(#linear-gradient-20);
      }

      .cls-23 {
        fill: url(#linear-gradient-21);
      }

      .cls-24 {
        fill: url(#linear-gradient-22);
      }

      .cls-25 {
        fill: url(#linear-gradient-23);
      }

      .cls-26 {
        fill: url(#linear-gradient-24);
      }

      .cls-27 {
        fill: url(#linear-gradient-25);
      }
`;

const FleetAdmiral= (props) => {
    return (


<Logo style={props.style} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200.58035 184.87881">
  <defs>
    <linearGradient id="linear-gradient" x1="754.13668" y1="-440.6366" x2="727.34724" y2="-388.20798" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -142.61298, -77.85077)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#777"/>
      <stop offset="0.5" stop-color="#fff"/>
      <stop offset="1" stop-color="#777"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="660.05222" y1="-320.04579" x2="743.43524" y2="-371.53589" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-3" x1="767.28998" y1="589.55337" x2="868.97673" y2="608.72162" gradientTransform="matrix(0.08785, 0.29603, 0.29603, -0.08785, -153.84726, -175.21984)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-4" x1="826.44008" y1="-318.79797" x2="844.9759" y2="-360.06497" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-5" x1="802.97373" y1="-365.42795" x2="836.6584" y2="-449.42211" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-6" x1="706.50512" y1="-474.78196" x2="679.71568" y2="-422.35334" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -189.84222, -44.40999)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-7" x1="612.41972" y1="-354.19026" x2="695.80274" y2="-405.68035" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -189.84222, -44.40999)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-8" x1="786.47456" y1="534.17326" x2="888.1613" y2="553.34151" gradientTransform="matrix(0.08785, 0.29603, 0.29603, -0.08785, -201.0755, -141.77897)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-9" x1="778.80855" y1="-352.94345" x2="797.34437" y2="-394.21045" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -189.84222, -44.40999)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-10" x1="755.34222" y1="-399.57353" x2="789.02689" y2="-483.56769" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -189.84222, -44.40999)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-11" x1="726.21704" y1="-529.43744" x2="699.42761" y2="-477.00882" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -170.29617, 9.11516)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-12" x1="632.13265" y1="-408.84581" x2="715.51567" y2="-460.3359" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -170.29617, 9.11516)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-13" x1="844.47894" y1="537.52285" x2="946.16569" y2="556.6911" gradientTransform="matrix(0.08785, 0.29603, 0.29603, -0.08785, -181.53, -88.25391)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-14" x1="798.52038" y1="-407.59887" x2="817.0562" y2="-448.86587" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -170.29617, 9.11516)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-15" x1="775.05401" y1="-454.22889" x2="808.73868" y2="-538.22305" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -170.29617, 9.11516)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-16" x1="783.91322" y1="-529.4229" x2="757.12378" y2="-476.99427" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -113.08993, 9.10092)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-17" x1="689.82882" y1="-408.83127" x2="773.21185" y2="-460.32136" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -113.08993, 9.10092)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-18" x1="860.8795" y1="592.83834" x2="962.56625" y2="612.00659" gradientTransform="matrix(0.08785, 0.29603, 0.29603, -0.08785, -124.3235, -88.26815)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-19" x1="856.21654" y1="-407.58433" x2="874.75237" y2="-448.85133" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -113.08993, 9.10092)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-20" x1="832.75016" y1="-454.21436" x2="866.43482" y2="-538.20852" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -113.08993, 9.10092)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-21" x1="802.99623" y1="-474.72642" x2="776.2068" y2="-422.29779" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -94.16896, -44.46516)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-22" x1="708.91183" y1="-354.13481" x2="792.29485" y2="-405.6249" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -94.16896, -44.46516)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-23" x1="813.87146" y1="626.69526" x2="915.5582" y2="645.86351" gradientTransform="matrix(0.08785, 0.29603, 0.29603, -0.08785, -105.40316, -141.83432)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-24" x1="875.29955" y1="-352.88785" x2="893.83537" y2="-394.15486" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -94.16896, -44.46516)" href="#linear-gradient"/>
    <linearGradient id="linear-gradient-25" x1="851.83316" y1="-399.51788" x2="885.51783" y2="-483.51204" gradientTransform="matrix(0.30879, 0, 0, -0.30879, -94.16896, -44.46516)" href="#linear-gradient"/>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="svg2">
      <g id="g3695">
        <g id="g5058">
          <path id="path3145" class="cls-1" d="M90.73678,26.761l9.205-25.56553,9.25024,25.60719,26.65024.10358L114.96914,42.61883,124.7352,69.39,100.00527,52.23635,75.91809,69.4161l9.37464-26.5234L63.18157,26.90532Z"/>
          <path id="path3143-9" class="cls-2" d="M100.00527,52.23635,75.91809,69.4161,99.90827,37.835Z"/>
          <path id="path4126-8" class="cls-3" d="M99.90689,37.83343,85.29273,42.8927,63.18157,26.90532Z"/>
          <path id="path5109-6" class="cls-4" d="M99.90714,37.83684,90.66047,26.72223l9.19362-25.2753Z"/>
          <path id="path5113-5" class="cls-5" d="M109.19205,26.80261l26.65024.10359L99.90888,37.83649Z"/>
          <path id="path5123-7" class="cls-6" d="M114.96914,42.61883,124.7352,69.39,99.90878,37.83848Z"/>
          <path id="path2170" class="cls-7" d="M90.73678,26.761l9.205-25.56553,9.25024,25.60719,26.65024.10358L114.96914,42.61883,124.7352,69.39,100.00527,52.23635,75.91809,69.4161l9.37464-26.5234L63.18157,26.90532Z"/>
        </g>
        <g id="g5076">
          <path id="path2178-6" class="cls-1" d="M28.79927,70.74536l9.205-25.56552L47.25453,70.787l26.65025.10359L53.03164,86.60324l9.76605,26.77116L38.06776,96.22076,13.98058,113.40052l9.37464-26.52341L1.24405,70.88973Z"/>
          <path id="path2180-2" class="cls-8" d="M38.06776,96.22076,13.98058,113.40052,37.97076,81.81941Z"/>
          <path id="path2182-7" class="cls-9" d="M37.96938,81.81784,23.35522,86.87711,1.24405,70.88973Z"/>
          <path id="path2184-0" class="cls-10" d="M37.96963,81.82125,28.723,70.70664l9.19361-25.2753Z"/>
          <path id="path2186-3" class="cls-11" d="M47.25453,70.787l26.65025.10358L37.97137,81.82092Z"/>
          <path id="path2188-9" class="cls-12" d="M53.03163,86.60324l9.76606,26.77116L37.97126,81.82289Z"/>
          <path id="path2190" class="cls-7" d="M28.79927,70.74536l9.205-25.56552L47.25453,70.787l26.65025.10359L53.03164,86.60324l9.76605,26.77116L38.06776,96.22076,13.98058,113.40052l9.37464-26.52341L1.24405,70.88973Z"/>
        </g>
        <g id="g5085">
          <path id="path2222-9" class="cls-1" d="M54.43206,141.1474l9.205-25.56552,9.25023,25.60719,26.65024.10358L78.66442,157.00528l9.76606,26.77117L63.70055,166.6228,39.61337,183.80257,48.988,157.27916,26.87685,141.29178Z"/>
          <path id="path2224-9" class="cls-13" d="M63.70055,166.6228,39.61337,183.80257l23.99019-31.58112Z"/>
          <path id="path2226-1" class="cls-14" d="M63.60217,152.21987,48.988,157.27915,26.87684,141.29176Z"/>
          <path id="path2228-7" class="cls-15" d="M63.60242,152.2233l-9.24666-11.11461,9.19361-25.2753Z"/>
          <path id="path2230-2" class="cls-16" d="M72.88732,141.18907l26.65024.10358L63.60416,152.223Z"/>
          <path id="path2232-3" class="cls-17" d="M78.66442,157.00527l9.76606,26.77116-24.82642-31.5515Z"/>
          <path id="path2234" class="cls-7" d="M54.43206,141.1474l9.205-25.56552,9.25023,25.60719,26.65024.10358L78.66442,157.00528l9.76606,26.77117L63.70055,166.6228,39.61337,183.80257,48.988,157.27916,26.87685,141.29178Z"/>
        </g>
        <g id="g5094">
          <path id="path2248-6" class="cls-1" d="M129.45435,141.12867l9.205-25.56552,9.25023,25.60718,26.65024.10359L153.6867,156.98655l9.76606,26.77116-24.72993-17.15364-24.08718,17.17976,9.37463-26.52341L101.89912,141.273l27.55522-.14437Z"/>
          <path id="path2250-5" class="cls-18" d="M138.72284,166.60405l-24.08718,17.17977,23.99019-31.58112Z"/>
          <path id="path2252-5" class="cls-19" d="M138.62447,152.20114l-14.61416,5.05927L101.89915,141.273Z"/>
          <path id="path2254-8" class="cls-20" d="M138.62472,152.20456,129.37805,141.09l9.19362-25.2753Z"/>
          <path id="path2256-1" class="cls-21" d="M147.90963,141.17032l26.65023.10358-35.93341,10.93031,9.28318-11.03388Z"/>
          <path id="path2258-4" class="cls-22" d="M153.68672,156.98653l9.766,26.77117-24.82642-31.55151Z"/>
          <path id="path2260" class="cls-7" d="M129.45435,141.12867l9.205-25.56552,9.25023,25.60718,26.65024.10359L153.6867,156.98655l9.76606,26.77116-24.72993-17.15364-24.08718,17.17976,9.37463-26.52341L101.89912,141.273l27.55522-.14437Z"/>
        </g>
        <g id="g5067">
          <path id="path2274-7" class="cls-1" d="M154.268,70.6728l9.205-25.56552,9.25024,25.60718,26.65023.10359L178.50034,86.53067l9.76606,26.77116L163.53647,96.1482,139.44929,113.328l9.37463-26.52341L126.71276,70.81717,154.268,70.6728Z"/>
          <path id="path2276-1" class="cls-23" d="M163.53649,96.1482,139.4493,113.328l23.99018-31.58111.097,14.40135Z"/>
          <path id="path2278-3" class="cls-24" d="M163.4381,81.74528l-14.61416,5.05927L126.71278,70.81717Z"/>
          <path id="path2280-8" class="cls-25" d="M163.43835,81.74869l-9.24668-11.11461,9.19363-25.2753Z"/>
          <path id="path2282-4" class="cls-26" d="M172.72326,70.71446l26.65023.10359-35.93341,10.9303Z"/>
          <path id="path2284-8" class="cls-27" d="M178.50034,86.53068l9.76606,26.77116L163.44,81.75033Z"/>
          <path id="path2286" class="cls-7" d="M154.268,70.6728l9.205-25.56552,9.25024,25.60718,26.65023.10359L178.50034,86.53067l9.76606,26.77116L163.53647,96.1482,139.44929,113.328l9.37463-26.52341L126.71276,70.81717,154.268,70.6728Z"/>
        </g>
      </g>
    </g>
  </g>
</Logo>
    )
}
export default FleetAdmiral