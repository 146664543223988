import React, { useState } from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Row, Col, Button } from "react-bootstrap"
import DonationModal from "./DonationModal"
import CorporateModal from "./CorporateModal"

import CommanderAndChief from "../SupportersPage/Individuals/CommanderAndChief"
import NavySecretary from "../SupportersPage/Individuals/NavySecretary"
import FleetAdmiral from "../SupportersPage/Individuals/FleetAdmiral"
import Admiral from "../SupportersPage/Individuals/Admiral"
import RearAdmiral from "../SupportersPage/Individuals/RearAdmiral"
import Captain from "../SupportersPage/Individuals/Captain"


function CorporateModalCheck(props) {
  const [modalShow, setModalShow] = useState(false)
  const [amount, setAmount] = useState(0)

  function showModalAmount() {
    setModalShow(true)
    setAmount(props.amount)
  }
  return (
    <>
      <Button onClick={e => showModalAmount(e)} className="ussIdahoButton btn-block">
        Donate ${(props.amount / 100).toLocaleString()}
      </Button>
      <CorporateModal
        amount={props.amount}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}


const DonateModalButton = () => {
return (
  <>
    <Row className="mb-4 text-center">
      {/* <Col md={4} className="my-2">
    <Modal 
        amount={2500}
    />
    </Col> */}
      <Col md={6} className="my-2">
       <div className="donateBox">
          <div className="supporterTitle" style={{backgroundColor: '#CDD5E0'}}>
            <h3 className="supporterLevels">Platinum</h3>
            <h4 className="supporterPrice">$50,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
    <li>Personal Message from Corporate CEO or Senior Leader on Supporter Webpage		</li>
		
    <li>Corporate Logo and Name on USS IDAHO Commissioning Committee Website Supporter page linked to corporate Website		</li>
        
    <li>Museum quality 1/192 Scale Model of USS IDAHO SSN 799 to display		</li>
        
    <li>Appx 12" x 18" Framed US and IDAHO Flags flown from USS IDAHO		</li>
        
    <li>Commemorative Ship's Bell		</li>
        
    <li>12 Reserved VIP seats at the commissioning		</li>
        
    <li>12 Complimentary tickets to the Chairman's Banquet the evening prior to the commissioning		</li>
        
    <li>12 Complimentary tickets to the VIP Breakfast the morning of the commissioning		</li>
        
    <li>Full page advertisement in Commemorative Commissioning Program		</li>
        
    <li>Invitations to USS IDAHO events in the state		</li>
        
    <li>Six sets of commemorative Coins		</li>
        
    <li>12 Commemorative Polo Shirts		</li>
        
    <li>24 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>100 USS IDAHO Lapel Pins		</li>
        
    <li>Honorary Plank Owner Plaque		</li>
            </ul>
          </div>
        
          <div>
            <CorporateModalCheck title="Platinum" amount={5000000} />
          </div>
        </div>
      </Col>
      <Col md={6} className="my-2">
      <div className="donateBox">
          <div className="supporterTitle" style={{backgroundColor: '#FFD700'}}>
            <h3 className="supporterLevels">Gold</h3>
            <h4 className="supporterPrice">$25,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
<li>Personal Message from Corporate CEO or Senior Leader on Supporter Webpage		</li>
		
<li>Corporate Logo and Name on USS IDAHO Commissioning Committee Website Supporter page linked to corporate Website		</li>
		
<li>Museum quality 1/192 Scale Model of USS IDAHO SSN 799 to display		</li>
		
<li>Appx 12" x 18" Framed US and IDAHO Flags flown from USS IDAHO		</li>
		
<li>Commemorative Ship's Bell		</li>
		
<li>6 Reserved VIP seats at the commissioning		</li>
		
<li>6 Complimentary tickets to the Chairman's Banquet the evening prior to the commissioning		</li>
		
<li>6 Complimentary tickets to the VIP Breakfast the morning of the commissioning		</li>
		
<li>Half page advertisement in Commemorative Commissioning Program		</li>
		
<li>Invitations to USS IDAHO events in the state		</li>
		
<li>Three sets of commemorative Coins		</li>
		
<li>6 Commemorative Polo Shirts		</li>
		
<li>12 USS IDAHO SSN 799 License Plate Frames		</li>
		
<li>50 USS IDAHO Lapel Pins		</li>
		
<li>Honorary Plank Owner Plaque		</li>
            </ul>
          </div>
          <div>
            <CorporateModalCheck title="Gold" amount={2500000} />
          </div>
        </div>
      </Col>
      </Row>
      {/* <Col md={4} className="my-2">
      <div className="donateBox">
          <div className="supporterTitle" style={{backgroundColor: '#C0C0C0'}}>
            <h3 className="supporterLevels">Silver</h3>
            <h4 className="supporterPrice">$20,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
              <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
              <li>Aliquam tincidunt mauris eu risus.</li>
              <li>Vestibulum auctor dapibus neque.</li>
              <li>Nunc dignissim risus id metus.</li>
              <li>Cras ornare tristique elit.</li>
              <li>Vivamus vestibulum ntulla nec ante.</li>
              <li>Praesent placerat risus quis eros.</li>
              <li>Fusce pellentesque suscipit nibh.</li>
              <li>Integer vitae libero ac risus egestas placerat.</li>
              <li>Vestibulum commodo felis quis tortor.</li>
              <li>Ut aliquam sollicitudin leo.</li>
              <li>Cras iaculis ultricies nulla.</li>
              <li>Donec quis dui at dolor tempor interdum.</li>
            </ul>
          </div>
          <div>
            <CorporateModalCheck title="Silver" amount={2000000} />
          </div>
        </div>
      </Col>
      <Col md={4} className="my-2">
      <div className="donateBox">
          <div className="supporterTitle" style={{backgroundColor: '#CD7F32'}}>
            <h3 className="supporterLevels">Bronze</h3>
            <h4 className="supporterPrice">$15,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
              <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
              <li>Aliquam tincidunt mauris eu risus.</li>
              <li>Vestibulum auctor dapibus neque.</li>
              <li>Nunc dignissim risus id metus.</li>
              <li>Cras ornare tristique elit.</li>
              <li>Vivamus vestibulum ntulla nec ante.</li>
              <li>Praesent placerat risus quis eros.</li>
              <li>Fusce pellentesque suscipit nibh.</li>
              <li>Integer vitae libero ac risus egestas placerat.</li>
              <li>Vestibulum commodo felis quis tortor.</li>
              <li>Ut aliquam sollicitudin leo.</li>
              <li>Cras iaculis ultricies nulla.</li>
              <li>Donec quis dui at dolor tempor interdum.</li>
            </ul>
          </div>
          <div>
            <CorporateModalCheck title="Bronze" amount={1500000} />
          </div>
        </div>
      </Col> */}
      <Row className="mb-4 text-center">
      <Col md={6} className="my-2">
      <div className="donateBox">
          <div className="supporterTitle" style={{backgroundColor: '#C0C0C0'}}>
            <h3 className="supporterLevels">Silver</h3>
            <h4 className="supporterPrice">$10,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
    <li>Corporate Logo and Name on USS IDAHO Commissioning Committee Website Supporter page linked to corporate Website		</li>
		
    <li>4 Reserved VIP seats at the commissioning		</li>
        
    <li>4 Complimentary tickets to the Chairman's Banquet the evening prior to the commissioning		</li>
        
    <li>4 Complimentary tickets to the VIP Breakfast the morning of the commissioning		</li>
        
    <li>Third-page advertisement in Commemorative Commissioning Program		</li>
        
    <li>Invitations to USS IDAHO events in the state		</li>
        
    <li>Two sets of commemorative Coins		</li>
        
    <li>4 Commemorative Polo Shirts		</li>
        
    <li>8 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>25 USS IDAHO Lapel Pins		</li>
        
    <li>Honorary Plank Owner Plaque		</li>
            </ul>
          </div>
          <div>
            <CorporateModalCheck title="Copper" amount={1000000} />
          </div>
        </div>
      </Col>
      <Col md={6} className="my-2">
      <div className="donateBox">
          <div className="supporterTitle" style={{backgroundColor: '#CD7F322'}}>
            <h3 className="supporterLevels">Bronze</h3>
            <h4 className="supporterPrice">$5,000</h4>
          </div>
          <div>
            <ul className="supporterPrize">
    <li>Corporate Logo and Name on USS IDAHO Commissioning Committee Website Supporter page linked to corporate Website		</li>
		
    <li>2 Reserved VIP seats at the commissioning		</li>
        
    <li>2 Complimentary tickets to the Chairman's Banquet the evening prior to the commissioning		</li>
        
    <li>2 Complimentary tickets to the VIP Breakfast the morning of the commissioning		</li>
        
    <li>Quarter-page advertisement in Commemorative Commissioning Program		</li>
        
    <li>Invitations to USS IDAHO events in the state		</li>
        
    <li>One sets of commemorative Coins		</li>
        
    <li>2 Commemorative Polo Shirts		</li>
        
    <li>4 USS IDAHO SSN 799 License Plate Frames		</li>
        
    <li>20 USS IDAHO Lapel Pins		</li>
        
    <li>Honorary Plank Owner Plaque		</li>
            </ul>
          </div>
          <div>
            <CorporateModalCheck title="Brass" amount={500000} />
          </div>
        </div>
      </Col>
      </Row>
  </>
)
  }

export default DonateModalButton
