import React, { useState } from "react"
import { Router, Link } from "@reach/router"
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/PageTitle"
import ContentBlock from "../components/ContentBlock/ContentBlock"

import DonationTabs from "../components/DonationForm/DonationTabs"

import CorporateTab from '../components/DonationForm/CorporateTab'
import IndividualTab from '../components/DonationForm/IndividualTab'
import CivicTab from '../components/DonationForm/CivicTab'

import DonationWrapper from "../components/DonationForm/DonationWrapper"
import DonateModalButton from "../components/DonationForm/DonateModalButton"

const Donate = () => {
  const [key, setKey] = useState("individual")
  
  return (
    <Layout>
      <SEO title="Donate" />
      <Container fluid className="container-fluid-full">
        <PageTitle subPage="Donate" pageTitle="Donate" link="/">
          {/* {page.pageTitle} */}
        </PageTitle>
        <ContentBlock>
          <Row className="mb-4">
            <Col sm={8}>
              <p>
                The USS IDAHO Commissioning Foundation has dedicated itself with
                raising funds to cover the costs of Commissioning activities,
                events leading up to and including Commissioning Week as well as
                habitability improvements, memorials and support programs for
                the crew and families.
              </p>
              <p>
                This covers incurred expenses which are customary of the
                commissioning of a US Navy warship. The funds raised ensure
                various commissioning events, community outreach and host
                functions are in place to celebrate the commissioning of our
                state’s namesake ship.
              </p>
              <p>
                Our fundraising goals include funding scholarships for the USS
                IDAHO Crew and their families via the Dolphin Scholarship
                Foundation. The Scholarship Foundation's charter is; easing the
                financial burden of undergraduate and vocational education for
                eligible members of the U.S. Submarine Force and their
                dependents (
                <a
                  href="http://dolphinscholarship.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://dolphinscholarship.org
                </a>
                )
              </p>
              <p>
                Additionally our goals include funding other support programs
                through the Navy's Morale, Welfare and Recreation programs and
                Family Service Center Programs.
              </p>
              <div className="display-card-box mb-4">
                <h3 className="text-center">
                The vast majority of your donated funds goes to the project. We are an all volunteer team!
                </h3>
              </div>
              <div>
                <p>
                *The numbers of tickets and reservations may have to be reduced based on capacity restrictions imposed by the Navy or local government restrictions.
                </p>
              </div>
              <Row className="my-4">
                <Col>
                  <h2 className="text-center my-4">Donor Levels</h2>
                  <Tabs
                    id="donation-tabs"
                    justify
                    activeKey={key}
                    onSelect={k => setKey(k)}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Tab eventKey="corporate" title="Corporate">
                    <CorporateTab />
                    </Tab>
                    <Tab
                      eventKey="individual"
                      title="Individual"
                      className="sponsorshipTabs"
                    >
                      <IndividualTab />
                    </Tab>
                    <Tab eventKey="civic" title="Small Business &amp; Civic">
                      <CivicTab />
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Col>
            <Col>
              <div className="card-box">
                <h3 className="text-center">
                  How To Support The USS IDAHO Commissioning Foundation
                </h3>
                <p>
                  Donations to the USS IDAHO Commissioning Foundation are
                  eligible for Federal Tax-Exempt under IRC Section 501(c)(3)
                  (EIN 84-5147328).
                </p>
                <p>
                  The USS IDAHO Commissioning Foundation is an Idaho non-profit
                  corporation staffed by volunteer patriots of all walks of life
                  who support the ship and the men and women of the United
                  States Navy.
                </p>
                <p>
                  We welcome monetary and non-monetary donations in support of
                  the USS IDAHO Commissioning Foundation Inc.
                </p>
                <p>
                  Use our DONATE buttons to the left to contribute securely by Credit
                  Card.
                </p>
                <p>
                  <strong>Checks may be made payable to:</strong>
                  <br /><br />
                  USS IDAHO Commissioning Foundation
                  <br /> (or USSICF)
                  <br />
                  <br />                  
                  <strong>and mailed to:</strong>
                  <br />
                  USS IDAHO Commissioning Foundation<br />
c/o Pulliam Associates<br />
7235 W Emerald St Suite A <br />
Boise ID  83704
                  <br />
                  <br />
                  <em>Refund Policy: No refunds</em>
                </p>
                <p>Please <a href="/about-us#contact-us">contact us</a> if you wish to make a donation of stock.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col></Col>
          </Row>
          <Row>
            <Col>{/* <DonationWrapper /> */}</Col>
          </Row>
        </ContentBlock>
      </Container>

    </Layout>
  )
}

export default Donate
